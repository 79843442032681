// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use "@angular/material" as mat;
// Plus imports for other components in your app.
// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();
// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$ForeignAdmits-primary: mat.define-palette(mat.$indigo-palette);
$ForeignAdmits-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);
// The warn palette is optional (defaults to red).
$ForeignAdmits-warn: mat.define-palette(mat.$red-palette);
// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$ForeignAdmits-theme: mat.define-light-theme((color: (primary: $ForeignAdmits-primary, accent: $ForeignAdmits-accent, warn: $ForeignAdmits-warn, ), ));
// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($ForeignAdmits-theme);

/* You can add global styles to this file, and also import other style files */

@import "https://fonts.googleapis.com/icon?family=Material+Icons";
@import "https://fonts.googleapis.com/css2?family=Poppins:wght@100;300;400;600&display=swap";
@import "https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;600&display=swap";
// 1. Include functions first (so you can manipulate colors, SVGs, calc, etc)
@import "bootstrap/scss/functions";
// 2. Include any default variable overrides here
@import "../src/scss/variables";
// 3. Include remainder of required Bootstrap stylesheets
@import "bootstrap/scss/variables";
@import "bootstrap/scss/mixins";
@import "bootstrap/scss/utilities";
// 4. Include any optional Bootstrap components as you like
// Layout & components
@import "bootstrap/scss/root";
@import "bootstrap/scss/reboot";
@import "bootstrap/scss/type";
@import "bootstrap/scss/images";
@import "bootstrap/scss/containers";
@import "bootstrap/scss/grid";
@import "bootstrap/scss/tables";
@import "bootstrap/scss/forms";
@import "bootstrap/scss/buttons";
@import "bootstrap/scss/transitions";
@import "bootstrap/scss/dropdown";
@import "bootstrap/scss/button-group";
@import "bootstrap/scss/nav";
@import "bootstrap/scss/navbar";
@import "bootstrap/scss/card";
@import "bootstrap/scss/accordion";
@import "bootstrap/scss/breadcrumb";
@import "bootstrap/scss/pagination";
@import "bootstrap/scss/badge";
@import "bootstrap/scss/alert";
@import "bootstrap/scss/progress";
@import "bootstrap/scss/list-group";
@import "bootstrap/scss/close";
@import "bootstrap/scss/toasts";
@import "bootstrap/scss/modal";
@import "bootstrap/scss/tooltip";
@import "bootstrap/scss/popover";
@import "bootstrap/scss/carousel";
@import "bootstrap/scss/spinners";
@import "bootstrap/scss/offcanvas";
@import "bootstrap/scss/placeholders";
@import "scss/fixed-plugin";
// Helpers
@import "bootstrap/scss/helpers";
// Utilities
@import "bootstrap/scss/utilities/api";
// scss-docs-end import-stack
// 5. Add additional custom code here
//customize css
@import "../src/scss/components";
@import "bootstrap-icons/font/bootstrap-icons";
@import "animate.css/animate.css";
html,
body {
    height: 100%;
    overflow-x: hidden;
}

@media screen and (min-width:318px) and (max-width:374px) {
    body {
        height: 100%;
        overflow-y: auto !important;
    }
}

@media screen and (min-width:375px) and (max-width:576px) {
    body {
        height: 100%;
        overflow-y: hidden !important;
    }
}

body {
    margin: 0;
    font-family: $font-family;
}

::-webkit-scrollbar {
    width: 5px;
    height: 5px;
}


/* Track */

::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px #D3D3D4;
    border-radius: 10px;
}


/* Handle */

::-webkit-scrollbar-thumb {
    background: #BCBEBF;
    border-radius: 5px;
}


/* Handle on hover */

::-webkit-scrollbar-thumb:hover {
    background: #BCBEBF;
}

.form-control,
.form-select {
    box-shadow: 0px 0px 10px rgba(14, 31, 53, 0.15);
    height: 44px !important;
}

@media screen and (min-width:320px) and (max-width:340px) {
    ngx-otp-input {
        .ngx-otp-input {
            border: 0px !important;
            border: #008DEA 2px solid !important;
            border-radius: 8px !important;
            padding: 0px !important;
            align-items: center;
            background: white !important;
            justify-content: center;
        }
        input:focus,
        select:focus {
            box-shadow: none !important;
            border: #008DEA 2px solid !important;
        }
        .input-height {
            height: 50px !important;
        }
    }
}

ngx-otp-input {
    .ngx-otp-input {
        border: 0px !important;
        border: #008DEA 2px solid !important;
        border-radius: 8px !important;
        padding: 0px !important;
        align-items: center;
        background: white !important;
        justify-content: center;
    }
    input:focus,
    select:focus {
        box-shadow: none !important;
        border: #008DEA 2px solid !important;
    }
    .input-height {
        height: 50px !important;
    }
}

@media screen and (min-width:1200px) {
    ngx-otp-input {
        .ngx-otp-input {
            border: 0px !important;
            border: #008DEA 2px solid !important;
            border-radius: 8px !important;
            padding: 0px !important;
            width: 50px !important;
            align-items: center;
            background: white !important;
            justify-content: center;
        }
        input:focus,
        select:focus {
            box-shadow: none !important;
            border: #008DEA 2px solid !important;
        }
        .input-height {
            height: 50px !important;
        }
    }
}

ngx-intl-tel-input {
    .iti__flag-container {
        border-right: 1px solid #D5DADE;
    }
    .iti__selected-flag {
        width: auto !important;
    }
}

form label span {
    color: #DC3545;
}

button:focus {
    box-shadow: none !important;
}

input:focus,
select:focus {
    box-shadow: none !important;
    border: 1px solid #ced4da !important;
}

.modal-content {
    border-radius: 20px;
}

.modal-position .modal-content {
    border-radius: 20px;
    width: 80%;
}

@media (width >=720px) {
    .modal-position .modal-dialog {
        left: 34%;
    }
}

@media (max-width: 576px) {
    .modal-content {
        width: 100% !important;
    }
}

@media screen and (min-width:360px) and (max-width:720px) {
    ngx-otp-input {
        .ngx-otp-input {
            border: 0px !important;
            border: #008DEA 2px solid !important;
            border-radius: 8px !important;
            background: white !important;
            padding: 0px !important;
            width: 44px !important;
            align-items: center;
            justify-content: center;
        }
        .input-height {
            height: 50px !important;
        }
        input {
            border-radius: 4px;
            background: #F5F5F5;
            padding: 12px;
            width: 320px;
            display: flex;
            align-items: flex-start;
            gap: 10px;
        }
        .ngx-otp-input:hover {
            width: 320px;
        }
    }
}