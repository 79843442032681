.btn {
    border-radius: 10px;
}
.btn-primary {
    color: white;
    background: linear-gradient(180deg, #008DEA 0%, #027BCA 100%);
    border-color:transparent;
    border-radius: 8px !important;
//     box-shadow: 0px 0px 10px rgba(15, 63, 98, 0.3), inset 2px 2px 4px rgba(14, 31, 53, 0.3);
}
.btn-primary:hover{
    color: white;
}

.btn-outline-primary:hover{
    color: white;
}