
.fixed-plugin{
    position: fixed;
    top: 80vh;
    right: 0;
    background: #008DEA;
    color:$white;
    z-index: 1031;
    border-radius: 8px 0 0 8px;
    text-align: center;
    padding-top:5px;
}
.plugin{
    display: none; 
}

.fixed-plugin:hover  .plugin{
    display: contents;
    position: fixed;
    top: 80vh;
    right: 0;
    width: min-content;
    font-size: 16px;
    padding:0px;
    background: #008DEA;
    color:$white;
    z-index: 1031;
    border-radius: 8px 0 0 8px;
    text-align: center;
    transition: all 1s ease;
    transform: translate(-60%, 0%);
}



